import { SEARCHABLE_TYPE } from 'Constants/enums';
import { API_ENDPOINTS } from 'Constants/env';
import { action, observable, runInAction, makeObservable } from 'mobx';
import { FromContactResponseDto, IContactDto } from 'Models/ContactModel';
import {
  ConversationModel,
  IConversationModel,
} from 'Models/ConversationModel';
import { IPersonModel, PersonModel, SearchResult } from 'Models/index';
import { BaseStore } from 'Stores/BaseStore';
import { RootStore } from 'Stores/RootStore';
import type {
  DirectoryMapType,
  GetDirectorySearchResponse,
  GetPersonContactSearchResponse,
  SearchResponseData,
} from 'Stores/SearchStore.types';
import { bugsnagClient } from 'Utils/logUtils';
import API from '../api';

export class SearchStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /** Cache object containing the `query`, `loading` and `data` of each directory search id */
  @observable public directoryMap: DirectoryMapType = {
    TRANSFER: { loading: false, query: null, data: null },
    USERS: { loading: false, query: null, data: null },
    CONVERSATIONS: { loading: false, query: null, data: null },
    DIRECTORY: { loading: false, query: null, data: null },
  };

  /** Fetches records for `TRANSFER` and `CONVERSATIONS` directory search ids */
  @action public getPersonContactSearch = async (
    directorySearchId: 'TRANSFER' | 'CONVERSATIONS',
    query,
    pageNumber = 1,
    appendResults = false,
    searchableTypes: SEARCHABLE_TYPE[] = [
      'SearchableDetailsConversation',
      'SearchableDetailsContact',
    ],
    pageSize = 20
  ): Promise<GetPersonContactSearchResponse> => {
    const searchPageNum = pageNumber;

    try {
      this.directoryMap[directorySearchId].loading = true;
      const response = await API.get<string, GetPersonContactSearchResponse>(
        API_ENDPOINTS.Search(query, searchableTypes, pageSize, searchPageNum)
      );

      if (response.status !== 200) {
        this.getSearchResponseError(
          directorySearchId,
          response.status,
          'getPersonContactSearchSuccess'
        );
        return response;
      }

      const model: SearchResponseData =
        appendResults && this.directoryMap?.[directorySearchId]
          ? {
              hits: this.directoryMap[directorySearchId]?.data?.hits,
              results: this.directoryMap[directorySearchId].data?.results,
            }
          : { hits: response.data.hits, results: [] };

      let hasResultTypeError = false;
      response.data?.results?.forEach(({ source, match, score }) => {
        runInAction(() => {
          if (source.searchableType === 'SearchableDetailsContact') {
            const ct = FromContactResponseDto(source as IContactDto);
            return model.results.push(new SearchResult(ct, match, score));
          }
          if (source.searchableType === 'SearchableDetailsConversation') {
            const conv = ConversationModel.FromResponseDto(
              source as IConversationModel
            );
            return model.results.push(new SearchResult(conv, match, score));
          }
          if (source.searchableType === 'SearchableDetailsPerson') {
            const pers = PersonModel.FromResponseDto(source as IPersonModel);
            if (!model.results.some((item) => item.source.id === pers.id)) {
              model.results.push(new SearchResult(pers, match, score));
            }
            return;
          }
          hasResultTypeError = true;
        });
      });

      if (hasResultTypeError) {
        const errMsg =
          'One or more search result(s) have invalid or missing searchableType, this may indicate a problem with the API';
        bugsnagClient.notify(errMsg, (event) => {
          event.severity = 'error';
          event.context = 'SearchStore';
          event.addMetadata('custom', {
            function: 'getPersonContactsSearchSuccess',
          });
        });
      }

      this.directoryMap[directorySearchId] = {
        loading: false,
        query,
        data: { hits: model.hits || 0, results: model.results || [] },
      };

      return response;
    } catch (err) {
      this.getDirectorySearchError(directorySearchId, err);
    }
  };

  /** Fetches records for `USERS` and `DIRECTORY` directory search ids */
  @action public getDirectorySearch = async (
    directorySearchId: 'USERS' | 'DIRECTORY',
    query = '',
    pageNumber = 1,
    appendResults = false,
    pageSize = 20
  ): Promise<GetDirectorySearchResponse> => {
    if (
      (directorySearchId === 'USERS' && this.directoryMap['USERS'].loading) ||
      (directorySearchId === 'DIRECTORY' &&
        this.directoryMap['DIRECTORY'].loading)
    )
      return;

    try {
      this.directoryMap[directorySearchId].loading = true;
      const directoryResults: GetDirectorySearchResponse = await API.get(
        API_ENDPOINTS.DirectorySearch(query, 'firstName', pageSize, pageNumber)
      );

      if (directoryResults.status !== 200) {
        this.getSearchResponseError(
          directorySearchId,
          directoryResults.status,
          'getDirectorySearchSuccess'
        );
        return directoryResults;
      }

      const model: SearchResponseData =
        appendResults && this.directoryMap?.[directorySearchId]
          ? {
              hits: this.directoryMap?.[directorySearchId]?.data?.hits,
              results: this.directoryMap?.[directorySearchId].data?.results,
            }
          : { hits: directoryResults.data.hits, results: [] };

      directoryResults.data?.people?.forEach((person) => {
        runInAction(() => {
          const pers = PersonModel.FromResponseDto(person);
          if (!model.results.some(({ source }) => source.id === pers.id)) {
            pers['searchableType'] = 'SearchableDetailsPerson';
            model.results.push(new SearchResult(pers, '', 0.0));
          }
        });
      });

      runInAction(() => {
        this.directoryMap[directorySearchId] = {
          loading: false,
          query,
          data: { hits: model.hits || 0, results: model.results || [] },
        };
      });
      return directoryResults;
    } catch (err) {
      return this.getDirectorySearchError(directorySearchId, err);
    }
  };

  /** Error message triggered when BE does not return a 200 status code */
  private getSearchResponseError = (directorySearchId, status, errFunction) => {
    this.directoryMap[directorySearchId].loading = false;
    bugsnagClient.notify(
      `${errFunction} response status was ${status}, where 200 was expected. Search results were not processed.`,
      (event) => {
        event.severity = 'error';
        event.context = 'SearchStore';
        event.addMetadata('custom', { function: errFunction });
      }
    );
  };

  /** Error message triggered by the `.catch` of a search store fetch */
  private getDirectorySearchError = (directorySearchId, err) => {
    this.directoryMap[directorySearchId].loading = false;
    this.rootStore.notificationStore.addAxiosErrorNotification(
      err,
      'Error Searching Directory',
      true,
      'tr',
      10,
      true
    );
    bugsnagClient.notify('Error Searching Directory', (event) => {
      event.severity = 'error';
      event.context = 'SearchStore';
      event.addMetadata('custom', { function: 'getDirectorySearchError' });
    });
    return err;
  };

  @action clearAllData = () => {
    this.directoryMap = {
      TRANSFER: { loading: false, query: null, data: null },
      USERS: { loading: false, query: null, data: null },
      CONVERSATIONS: { loading: false, query: null, data: null },
      DIRECTORY: { loading: false, query: null, data: null },
    };
  };
}

export default SearchStore;
