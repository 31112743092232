import { API_ENDPOINTS } from 'Constants/env';
import type { PersonModel } from 'Models/index';
import type { NotificationStore } from 'Stores/index';
import { bugsnagClient } from 'Utils/logUtils';
import API from '../api';

export const searchPersonBySip = async (
  sip: string,
  notification: NotificationStore
): Promise<PersonModel | null> => {
  try {
    const results = await API.get<PersonModel>(API_ENDPOINTS.SipSearch(sip));
    return results.data;
  } catch (error) {
    if (error?.response?.status !== 404) {
      notification.addAxiosErrorNotification(
        error,
        'Error Searching Person by SIP',
        true,
        'tr',
        10,
        true
      );
      bugsnagClient.notify('Error Searching Person by SIP', (event) => {
        event.severity = 'error';
        event.context = 'SearchStore';
        event.addMetadata('custom', { function: 'searchPersonBySip' });
      });
    }
    return null;
  }
};
