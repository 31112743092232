import { STATE_PRESENCE } from 'Constants/enums';
import {
  API_ENDPOINTS,
  IS_ELECTRON,
  PRESENCE_AWAY_TIME,
  PRESENCE_HEARTBEAT,
  PRESENCE_IDLE_TIME,
  TIMEZONE_IDENTIFIER,
  VIDEO_URL,
} from 'Constants/env';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import {
  IUnreadCountsResult,
  IUnreadMessageAndMentionCounts,
} from 'Interfaces/components';
import { assign, isEmpty, max } from 'lodash';
import {
  IReactionDisposer,
  ObservableMap,
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { createTransformer, fromPromise, now } from 'mobx-utils';
import type { IPromiseBasedObservable } from 'mobx-utils';
import ConversationModel from 'Models/ConversationModel';
import { IPinnedMessages } from 'Models/PinnedMessageModel';
import {
  IPresenceModel,
  IPresenceUpdateResponse,
  PresenceModel,
} from 'Models/PresenceModel';
import MessageStatusModel, { IMessageStatusModel } from 'Models/StatusModel';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import { sendIpcOpenVideo, sendIpcUnreadCounts } from 'Utils/ipcRendererEvents';

import { configureConferencePopupWindow } from 'Utils/windowUtils';
import API from '~/api';
import { BaseStore } from './BaseStore';

export class UiStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action
  clearAllData = () => {
    this.presenceById.clear();
    // [BC-835] WARNING: You now must manually call `UiStore#updatePresence('OffLine'...)` BEFORE `RootStore.clearAllData()` when logging out
    //this.updatePresence('OffLine', false, '');
    document.title = '';
    this.conversationUnreadCounts.clear();
    if (this.presenceReportTick !== null) {
      this.presenceReportTick();
      this.presenceReportTick = null;
    }

    if (this.presenceSyncTick !== null) {
      this.presenceSyncTick();
      this.presenceSyncTick = null;
    }
  };

  @observable public shouldShowDndBanner = true;

  @action
  setShouldShowDndBanner = (shouldShow: boolean) =>
    (this.shouldShowDndBanner = shouldShow);

  @observable public topBarContactPageNumber = 1;
  @action setTopBarContactPageNumber = (pageNumber: number) =>
    (this.topBarContactPageNumber = pageNumber);

  @observable public topBarSearchValue: string;
  @action setTopBarSearchValue = (input: string) =>
    (this.topBarSearchValue = input);

  @observable
  showDropDownBox: { show: boolean; draggedOn: boolean } = {
    show: false,
    draggedOn: false,
  };

  @action
  setShowDropDownBox = (value: { show: boolean; draggedOn: boolean }) =>
    (this.showDropDownBox = value);

  @observable
  wantedUrl = '';

  @action
  setWantedURL = (url: string) => (this.wantedUrl = url);

  @observable
  fileDeleteModal: { show: boolean; externalId: string; messageId: string } = {
    show: false,
    externalId: '',
    messageId: '',
  };

  @action
  setFileDeletePopup = (value: {
    show: boolean;
    externalId: string;
    messageId: string;
  }) => (this.fileDeleteModal = value);

  @observable
  copiedConferenceId = '';

  @action
  setCopiedConferenceId = (conferenceId: string) =>
    (this.copiedConferenceId = conferenceId);

  @observable
  sendingMessage = false;

  @action
  setSendMessagePending = (state: boolean) => (this.sendingMessage = state);

  @observable
  showMoreActionOptions: { conversationId: string; show: boolean } = {
    conversationId: '',
    show: false,
  };

  @action
  setMoreActionOptions = (value: { conversationId: string; show: boolean }) =>
    (this.showMoreActionOptions = value);

  @observable
  openingGroupModalFrom: 'edit' | 'top-bar' = null;

  @action
  setOpeningGroupModalFrom = (val: 'edit' | 'top-bar') =>
    (this.openingGroupModalFrom = val);

  @observable
  selectedAdHocParticipants = null;

  @action
  setSelectedParticipants = (value) => (this.selectedAdHocParticipants = value);

  @observable
  selectedTopBarUsers: string[] = null;

  @action
  setSelectedTopBarUsers = (value) => (this.selectedTopBarUsers = value);

  @observable
  displayForbiddenScreen = false;

  @action
  setDisplayForbiddenScreen = (state: boolean) =>
    (this.displayForbiddenScreen = state);

  @observable
  groupModalOpen: boolean;

  @action
  setGroupModal = (value: boolean) => (this.groupModalOpen = value);

  @observable
  activePinnedConversationId = '';

  @action
  setActiveConversationIdPinnMess = (conversationId: string) =>
    (this.activePinnedConversationId = conversationId);

  @observable
  loggedInMessageStatus: MessageStatusModel = new MessageStatusModel(
    this.rootStore.personStore.loggedInPersonId,
    '',
    ''
  );

  @observable
  listOfPinnedMessages: ObservableMap<string, IPinnedMessages[]> =
    observable.map();

  presenceById: ObservableMap<string, PresenceModel> = observable.map();

  messageStatusesById: ObservableMap<string, MessageStatusModel> =
    observable.map();

  presenceLoadStatus: ObservableMap<
    string,
    IPromiseBasedObservable<AxiosResponseT<PresenceModel>>
  > = observable.map();

  @observable
  public messageStatusPutStatus: IPromiseBasedObservable<
    AxiosResponseT<IMessageStatusModel>
  > = fromPromise.resolve();

  @observable
  userSmsCantSendError: ObservableMap<string> = observable.map();

  @action
  setChatErrorSend = (conversationId: string) => {
    this.userSmsCantSendError.set(conversationId, 'false');
  };

  @observable
  cursorPosition: number;

  @action
  setCursorPosition = (cp: number) => (this.cursorPosition = cp);

  @observable
  messageIdToScroll = '';

  @action
  setMessageIdToScroll = (el: string) => (this.messageIdToScroll = el);

  @observable
  openTopbarDialpad = false;

  @action
  setOpenTopbarDialpad = (value: boolean) => (this.openTopbarDialpad = value);

  @observable
  loadingConversationForSidebarInfo = false;

  @action
  setLoadingConversationForSidebarInfo = (value: boolean) =>
    (this.loadingConversationForSidebarInfo = value);

  @observable
  openedRightSidebarsOrder: ObservableMap<string, number> = observable.map<
    string,
    number
  >();

  @action
  setOpenedRightSidebarsOrder = (
    value:
      | 'sidebar-info'
      | 'dial-pad'
      | 'pinned-messages'
      | 'video-chat-history'
  ) => {
    let maxValue = max(Array.from(this.openedRightSidebarsOrder.values()));
    maxValue = maxValue || 10;
    this.openedRightSidebarsOrder.set(value, maxValue + 1);
  };

  @action
  removeFromOpenedRightSidebarsOrder = (
    key: 'sidebar-info' | 'dial-pad' | 'pinned-messages' | 'video-chat-history'
  ) => {
    this.openedRightSidebarsOrder.delete(key);
  };

  @computed
  get IsCallSidebarFixedOnChatPage() {
    if (!this.rootStore.routerStore.currentPath?.includes('/chat')) {
      return false;
    }

    const IsActiveCall = this.rootStore.phoneStore.IsActiveCall;
    const isFixed =
      this.rootStore.preferenceStore.preferences.floatingSoftphone;
    const isOnlyDialpadOpened =
      this.openedRightSidebarsOrder.has('dial-pad') &&
      this.openedRightSidebarsOrder.size === 1;
    const noSidebarsAreOpened = !this.openedRightSidebarsOrder.size;
    if (isFixed) {
      return IsActiveCall ? noSidebarsAreOpened || isOnlyDialpadOpened : true;
    }
    return false;
  }

  @computed
  get IsCallSidebarFixedOnCallPage() {
    if (!this.rootStore.routerStore.currentPath?.includes('/calls')) {
      return false;
    }

    const IsActiveCall = this.rootStore.phoneStore.IsActiveCall;
    const isFixed =
      this.rootStore.preferenceStore.preferences.floatingSoftphone;
    if (isFixed) {
      if (this.openedRightSidebarsOrder.has('sidebar-info')) {
        return !IsActiveCall;
      } else {
        return true;
      }
    }
    return false;
  }

  @computed
  get IsCallSidebarFixedOnVideoPage() {
    if (!this.rootStore.routerStore.currentPath?.includes('/video-app'))
      return false;

    const IsActiveCall = this.rootStore.phoneStore.IsActiveCall;
    const isFixed =
      this.rootStore.preferenceStore.preferences.floatingSoftphone;
    if (isFixed) {
      return !(
        this.openedRightSidebarsOrder.has('video-chat-history') && IsActiveCall
      );
    }
    return false;
  }

  @computed
  get IsCallSidebarFixedOnOtherPages() {
    const isOtherPages = !['/chat', '/calls', '/video-app'].some((path) =>
      this.rootStore.routerStore.currentPath?.includes(path)
    );
    if (!isOtherPages) {
      return false;
    }

    const isFixed =
      this.rootStore.preferenceStore.preferences.floatingSoftphone;
    // needed for rerendering purpose
    const IsActiveCall = this.rootStore.phoneStore.IsActiveCall; //prettier-ignore
    return isFixed;
  }

  @computed
  get IsCallSidebarFixed() {
    return (
      this.IsCallSidebarFixedOnCallPage ||
      this.IsCallSidebarFixedOnChatPage ||
      this.IsCallSidebarFixedOnVideoPage ||
      this.IsCallSidebarFixedOnOtherPages
    );
  }

  @observable
  isVideoChatHistoryOpened = false;

  @action
  setIsVideoChatHistoryOpened = (value: boolean) =>
    (this.isVideoChatHistoryOpened = value);

  @observable
  hideSecondaryMenu: boolean = window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT;

  @action
  setHideSecondaryMenu = (value: boolean) => (this.hideSecondaryMenu = value);

  isTouchSupported = () =>
    !!navigator.maxTouchPoints &&
    !window.location.pathname.includes('voicemail') &&
    !window.location.pathname.includes('fax') &&
    !window.location.pathname.includes('directory');

  handleDownloadWithLink = (url: string, fileName: string) => {
    if (IS_ELECTRON) {
      // @ts-ignore
      window.ipcRenderer.send('download', url);
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const element = document.createElement('a');
        element.href = urlCreator.createObjectURL(this.response);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      };
      xhr.send();
    }
  };

  selectPersonPresenceStatus = createTransformer((personId: number) =>
    this.presenceById.has(personId.toString())
      ? this.presenceById.get(personId.toString())
      : PresenceModel.CreateOffLineStatus(personId)
  );

  selectPersonMessageStatus = createTransformer((personId: number) => {
    const pIdStr = personId.toString();
    if (!this.messageStatusesById.has(pIdStr)) {
      return MessageStatusModel.CreateOffLineStatus(personId);
    }
    return this.messageStatusesById.get(pIdStr);
  });

  uppercaseFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  @action
  insertLocalPushPresence = (presenceUpdate: PresenceModel) => {
    const pIdStr = presenceUpdate.personId.toString();
    if (this.presenceById.has(pIdStr)) {
      const exPresence = this.presenceById.get(pIdStr);
      if (exPresence.state !== presenceUpdate.state) {
        exPresence.setState(presenceUpdate.state);
        exPresence.setInfo(presenceUpdate.info);
        this.presenceById.set(pIdStr, exPresence);
      }
    } else {
      this.presenceById.set(pIdStr, presenceUpdate);
    }
  };

  containerWidth = () => {
    const { personStore, uiStore, phoneStore } = this.rootStore;
    return !personStore?.showPersonDetails.id &&
      !personStore?.showPersonDetails.type &&
      !uiStore.openTopbarDialpad &&
      !phoneStore.ActivePhoneCall &&
      !phoneStore.incomingPhoneCalls.length
      ? 12
      : 6;
  };

  @action
  insertLocalPushStatusMessage = (statusMessage: MessageStatusModel) => {
    const pIdStr = statusMessage.personId.toString();
    if (this.messageStatusesById.has(pIdStr)) {
      const exStatusMess = this.messageStatusesById.get(pIdStr);
      if (exStatusMess.title !== statusMessage.title) {
        exStatusMess.setTitle(statusMessage.title);
      }
      if (exStatusMess.message !== statusMessage.message) {
        exStatusMess.setMessage(statusMessage.message);
      }
      this.messageStatusesById.set(pIdStr, exStatusMess);
    } else {
      this.messageStatusesById.set(pIdStr, statusMessage);
    }
  };

  @action
  loadPresenceIfMissing = (personId: number) => {
    const pIdStr = personId.toString();
    if (!this.presenceById.has(pIdStr)) {
      this.presenceById.set(
        pIdStr,
        PresenceModel.CreateOffLineStatus(personId)
      );
      if (this.allPeoplePresenceLoaded === true) {
        this.loadPresence(personId);
      }
    }
    return this.presenceById.get(pIdStr);
  };

  @observable public loadPeoplePresence: IPromiseBasedObservable<
    AxiosResponseT<IPresenceModel>
  > = null;

  allPeoplePresenceLoaded = false;

  @observable allPeoplesPresenceThrottleDate = 0;

  @action
  loadAllPeoplesPresence = async () => {
    // If saved timestamp is bigger than now - 30s, don't call the API
    if (this.allPeoplesPresenceThrottleDate > Date.now() - 30000) return;
    await this.rootStore.personStore.waitUntilLoggedIn();
    this.loadPeoplePresence = fromPromise(
      API.get(API_ENDPOINTS.PresenceEveryone)
    );
    return this.loadPeoplePresence.then(
      (resp) => {
        this.loadAllPeoplePresenceGetSuccess(resp.data);
        // Refresh saved timestamp
        this.allPeoplesPresenceThrottleDate = Date.now();
      },
      (reason) => {
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error Loading Everyones Presence'
        );
        // Refresh saved timestamp
        this.allPeoplesPresenceThrottleDate = Date.now();
      }
    );
  };

  @action
  public loadAllPeoplePresenceGetSuccess = (resp) => {
    const updatedPresences = {} as { [personId: string]: PresenceModel };
    for (const pResp of resp.statuses) {
      let presenceModel: PresenceModel = null;
      const personIdStr = pResp.personId.toString();
      if (pResp.state === 'OffLine') {
        presenceModel = PresenceModel.CreateOffLineStatus(pResp.personId);
      } else {
        if (this.presenceById.has(personIdStr)) {
          const exPresence = this.presenceById.get(personIdStr);
          exPresence.setState(pResp.state);
          exPresence.setInfo(pResp.info);
          presenceModel = exPresence;
        } else {
          presenceModel = PresenceModel.FromResponseDto(pResp);
        }
      }
      updatedPresences[personIdStr] = presenceModel;
    }
    // Object.keys(updatedPresences).forEach((pId) => {
    //     if(this.presenceById.has(pId)){
    //         const exp = this.presenceById.get(pId);
    //         exp.setState(updatedPresences[pId].state);
    //         exp.setInfo(updatedPresences[pId].state);
    //     }
    //     else {
    //         this.presenceById.set(pId, updatedPresences[pId]);
    //     }
    // });
    for (const exPr of Array.from(this.presenceById.keys())) {
      if (isEmpty(updatedPresences[exPr])) {
        this.presenceById.delete(exPr);
      }
    }
    this.presenceById.merge(updatedPresences);
    this.allPeoplePresenceLoaded = true;
  };

  @observable public loadPeopleStatus: IPromiseBasedObservable<
    AxiosResponseT<IPresenceModel>
  > = null;

  @action
  loadAllMessageStatuses = async () => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    this.loadPeopleStatus = fromPromise(
      API.get(API_ENDPOINTS.StatusMessagesEveryone)
    );
    return this.loadPeopleStatus.then(
      (resp) => {
        this.loadAllPeopleMessageStatusGetSuccess(resp);
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error Loading Everyones Presence'
        )
    );
  };

  @observable
  deletePopupDisplay = false;

  @action
  setDeletePopupDisplay = (status: boolean) =>
    (this.deletePopupDisplay = status);

  @action
  private loadAllPeopleMessageStatusGetSuccess = (resp) => {
    const updatedPresences = {} as { [personId: string]: MessageStatusModel };
    for (const pResp of resp.data.statusMessages) {
      let statusModel: MessageStatusModel = null;
      const personIdStr = pResp.personId.toString();
      if (this.messageStatusesById.has(personIdStr)) {
        const exStatusMessage = this.messageStatusesById.get(personIdStr);
        exStatusMessage.setMessage(pResp.message);
        exStatusMessage.setTitle(pResp.title);
        statusModel = exStatusMessage;
      } else {
        updatedPresences[personIdStr] =
          MessageStatusModel.FromResponseDto(pResp);
      }
    }
    this.messageStatusesById.merge(updatedPresences);
    this.allPeoplePresenceLoaded = true;
  };

  handleLoadMoreContacts = async (
    getExtrContact?: boolean,
    getOnlyPerson?: boolean
  ) => {
    const search = this.rootStore.searchStore;
    let extrContactResp = [];
    this.setTopBarContactPageNumber(this.topBarContactPageNumber + 1);

    if (getOnlyPerson) {
      const respPeople = await search.getDirectorySearch(
        'USERS',
        this.topBarSearchValue,
        this.topBarContactPageNumber,
        true
      );
      return isEmpty(respPeople.data.people);
    }

    if (getExtrContact) {
      extrContactResp =
        await this.rootStore.personStore.getSearchContactsTopBar(
          20,
          this.topBarContactPageNumber,
          '',
          this.topBarSearchValue,
          true
        );
    }
    const respConv = await search.getPersonContactSearch(
      'CONVERSATIONS',
      this.topBarSearchValue,
      this.topBarContactPageNumber,
      true
    );
    const respPeople = await search.getDirectorySearch(
      'USERS',
      this.topBarSearchValue,
      this.topBarContactPageNumber,
      true
    );

    return (
      isEmpty(respConv?.data.results) &&
      isEmpty(extrContactResp) &&
      isEmpty(respPeople?.data.people)
    );
  };

  searchContacts = (searchQuery?: string, shouldAppend?: boolean) => {
    const search = this.rootStore.searchStore;
    this.setTopBarSearchValue(searchQuery);
    if (this.topBarSearchValue && this.topBarContactPageNumber > 1)
      this.setTopBarContactPageNumber(1);

    return search.getPersonContactSearch(
      'CONVERSATIONS',
      searchQuery,
      1,
      shouldAppend
    );
  };

  copyToClipboard = (str: string[]) => {
    const el = document.createElement('textarea');
    str.forEach((value: string, index) => {
      const addLastString = index < str.length - 1 ? '\n' : '';
      el.value += value + addLastString;
    });
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  /**
   * Navigates the Video popup to the Conference URL.
   *
   * **NOTE:** In Electron, this will send an `open-video` message with the URL via `ipcRenderer`. `windowRef` is NOT used in this case.
   * @param conferenceId The persistent `conferenceId` (prefixed with `ci-`) to pass to the Video popup window.
   * @param [windowRef] Reference to a `Window` object. Does nothing if `IS_ELECTRON` (rather, we use the `ipcRenderer` to send an `open-video` message).
   * @param autoJoin ...
   */
  @action
  navigateVideoConferenceToSession = (
    conferenceId: string,
    windowRef?: Window,
    autoJoin?: boolean
  ) => {
    if (!IS_ELECTRON) {
      const wr = windowRef || configureConferencePopupWindow();
      wr.location.href = VIDEO_URL + conferenceId + `?auto_login=true`;
      wr.focus();
    } else if (autoJoin) {
      sendIpcOpenVideo(
        VIDEO_URL + conferenceId + '?auto_join=true' + `&auto_login=true`
      );
    } else {
      sendIpcOpenVideo(VIDEO_URL + conferenceId + `?auto_login=true`);
    }
  };

  @action
  loadPresence = async (personId: number) => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    const loadPresenceByPersonIdPbo = fromPromise<
      AxiosResponseT<PresenceModel>
    >(API.get(API_ENDPOINTS.PresencePersonById(personId.toString())));
    loadPresenceByPersonIdPbo.then(
      (resp) => this.loadPresenceByPersonIdGetSuccess(resp, personId),
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error Loading Person'
        )
    );
    this.presenceLoadStatus.set(personId.toString(), loadPresenceByPersonIdPbo);
    return loadPresenceByPersonIdPbo;
  };

  @action
  private loadPresenceByPersonIdGetSuccess = (
    statusResp: AxiosResponseT<IPresenceModel>,
    personId: number
  ) => {
    const pIdStr = personId.toString();
    if (statusResp.status === 204) {
      const presenceModel = PresenceModel.CreateOffLineStatus(personId);
      this.presenceById.set(pIdStr, presenceModel);
    } else {
      const presenceUpdate = PresenceModel.FromResponseDto(statusResp.data);
      if (this.presenceById.has(pIdStr)) {
        const exPresence = this.presenceById.get(pIdStr);
        if (exPresence.state !== presenceUpdate.state) {
          exPresence.setState(presenceUpdate.state);
          exPresence.setInfo(presenceUpdate.info);
          this.presenceById.set(pIdStr, exPresence);
        }
      } else {
        this.presenceById.set(pIdStr, presenceUpdate);
      }
    }
  };

  @observable
  conferencePresenceStatus = {
    state: 'OffLine',
    doNotDisturb: false,
    info: '',
  } as IPresenceModel;

  @observable
  messageStatus = {
    title: '',
    message: '',
  } as IMessageStatusModel;

  @action
  private updatePresenceStatusSuccess = (
    response: AxiosResponseT<IPresenceUpdateResponse>
  ) => {
    const loggedInPersonId =
      this.rootStore.personStore.loggedInPersonId.toString();

    this.conferencePresenceStatus = response.data.current;
    if (
      response.data.changed ||
      this.presenceById.get(loggedInPersonId)?.state !==
        response.data.current.state
    ) {
      this.insertLocalPushPresence(
        PresenceModel.FromResponseDto(response.data.current)
      );
      this.setShouldShowDndBanner(true);
    }
  };

  @action
  private updateMessageStatusSuccess = (response: any) => {
    this.messageStatus = response.data as IMessageStatusModel;
    const data = MessageStatusModel.FromResponseDto(response.data);
    this.loggedInMessageStatus = data;
    this.messageStatusesById.set(data.personId.toString(), data);
  };

  @action
  private updatePresenceStatusFailure = (reason) => {
    this.rootStore.notificationStore.addAxiosErrorNotification(
      reason,
      'Error updating presence'
    );
  };

  @action
  public updatePresence = async (state: string, sticky: boolean, info = '') => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    try {
      const resp = await fromPromise(
        API.put(API_ENDPOINTS.Presence, { sticky, state, info })
      );
      this.updatePresenceStatusSuccess(resp);
      return resp.data;
    } catch (err) {
      this.updatePresenceStatusFailure(err.request?.response);
      return false;
    }
  };

  @action
  public updateMessageStatus = async (title: string, message: string) => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    const updateStatus = { title, message, change: true };
    this.messageStatusPutStatus = fromPromise(
      API.put(API_ENDPOINTS.StatusMessages, updateStatus)
    );
    this.messageStatusPutStatus.then(
      this.updateMessageStatusSuccess,
      this.updatePresenceStatusFailure
    );
    return this.messageStatusPutStatus;
  };

  // ** UI Bridge state tracking **

  /**
   * ** Presence **
   */
  /** Track Presence information to send to the server */
  @observable
  presenceState = {
    state: 'OffLine',
    doNotDisturb: false,
    info: '',
  } as IUiBridgePresenceState;

  /**
   * returning me if the conference exist in any conversation;
   */

  @computed
  get IsOnVideoConference() {
    return (
      !isEmpty(this.conferencePresenceStatus) &&
      this.conferencePresenceStatus.state === 'OnCall' &&
      !isEmpty(this.conferencePresenceStatus.info) &&
      this.conferencePresenceStatus.info
        .toUpperCase()
        .indexOf('voxeet'.toUpperCase()) >= 0
    );
  }

  @action
  updatePresenceState = (
    presenceStateUpdate: Partial<IUiBridgePresenceState>
  ) => {
    assign(this.presenceState, presenceStateUpdate);
  };

  @observable
  browserTabFocused = true;

  @action
  setBrowserTabFocused = (flag: boolean) => {
    this.browserTabFocused = flag;
  };

  @observable
  lastFocus = now();

  @action
  setLastFocus = (lastFocus: number) => (this.lastFocus = lastFocus);

  @observable
  lastBlur: number = null;

  @action
  setLastBlur = (lastBlur: number) => (this.lastBlur = lastBlur);

  @observable
  lastKeyboardMouseActivity = now();

  @action
  setLastKeyboardMouseActivity = (lastKeyboardMouseActivity: number) =>
    (this.lastKeyboardMouseActivity = lastKeyboardMouseActivity);

  @observable
  lastPhoneActivity: number = null;

  @action
  setLastPhoneActivity = (lastPhoneActivity: number) =>
    (this.lastPhoneActivity = lastPhoneActivity);

  @observable
  lastPresenceReport: number = null;

  @action
  setLastPresenceReport = (lastPresenceReport: number) =>
    (this.lastPresenceReport = lastPresenceReport);

  /**
   * Whether the window has been focused, or there was keyboard/mouse
   * activity within the last `PRESENCE_HEARTBEAT`
   */
  @computed
  get HasRecentActivity() {
    return [this.lastKeyboardMouseActivity, this.lastFocus].some(
      (a) => now(PRESENCE_HEARTBEAT) - a < PRESENCE_HEARTBEAT
    );
  }

  /**
   * Select whether the window has been focused, or there was keyboard/mouse
   * activity within the last `withinMs` milliseconds from the latest Presence update
   * (whose interval is determined by `PRESENCE_HEARTBEAT`)
   */
  selectHasRecentActivityWithin = createTransformer((withinMs: number) => {
    return [this.lastKeyboardMouseActivity, this.lastFocus].some(
      (a) => now(PRESENCE_HEARTBEAT) - a < withinMs
    );
  });

  /** Whether `window` **is** Focused and Active */
  @computed
  get IsFocused() {
    return this.lastBlur === null || this.lastFocus > this.lastBlur;
  }

  /** Whether `window` is **not** Focused and Active */
  @computed
  get IsBlurred() {
    return this.lastBlur !== null && this.lastBlur > this.lastFocus;
  }

  private presenceSyncTick: IReactionDisposer = null;

  private presenceReportTick: IReactionDisposer = null;

  /** Initialize the Presence `reaction` view, which observes the synchronized `now(PRESENCE_HEARTBEAT)`, window visibility, and phone call activity */
  startPresenceReportTick = () => {
    this.presenceReportTick = reaction(
      () =>
        ({
          isBlurred: this.IsBlurred,
          // All calls to `now(PRESENCE_HEARTBEAT)` are synchronized to `PRESENCE_HEARTBEAT`
          presenceHeartbeat: now(PRESENCE_HEARTBEAT),
          phoneCallConnected:
            this.rootStore.phoneStore.ActivePhoneCall &&
            this.rootStore.phoneStore.ActivePhoneCall.isCallConnected,
          isLoggedIn: this.rootStore.personStore.IsLoggedIn,
        } as IPresenceTickReactionData),
      (tickData) => {
        if (!tickData.isLoggedIn) {
          return;
        }
        let nextState: STATE_PRESENCE = this.presenceState.state;
        // If there is NOT a call in progress...
        if (!tickData.phoneCallConnected) {
          // If the window is NOT visible and focused
          if (tickData.isBlurred) {
            const sinceLastBlur = tickData.presenceHeartbeat - this.lastBlur;
            const sinceLastPhoneActivity =
              tickData.presenceHeartbeat - this.lastPhoneActivity;
            const sinceMostRecent = Math.min(
              sinceLastBlur,
              sinceLastPhoneActivity
            );
            if (
              sinceMostRecent > PRESENCE_IDLE_TIME &&
              this.presenceState.state === 'Active'
            ) {
              nextState = 'Idle';
            } else if (
              sinceMostRecent > PRESENCE_AWAY_TIME &&
              this.presenceState.state === 'Idle'
            ) {
              nextState = 'Away';
            } else if (
              sinceMostRecent < PRESENCE_IDLE_TIME &&
              this.presenceState.state === 'OnCall'
            ) {
              nextState = 'Active';
            }
          } else {
            nextState = 'Active';
          }
        } else {
          // Either on a Call or Conference
          nextState = 'OnCall';
          this.setLastPhoneActivity(tickData.presenceHeartbeat);
        }

        /*
                    If `state` changed OR `PRESENCE_HEARTBEAT` has elapsed since the last report,
                    update the necessary tracking properties and make an API call to report Presence
                */
        const isNewPresenceState = nextState !== this.presenceState.state;
        /*
                    Check if it has been at least PRESENCE_HEARTBEAT - 5 seconds since last heartbeat
                    I give this a 5 second buffer, because on blur, if I set it to 20 seconds exactly, it might skip it
                    depending on the exact time that the reaction runs. This way, even if it happens to skip, it will
                    still report in 30 seconds, which is what the server requires.
                */
        const presenceHeartbeatElapsed =
          tickData.presenceHeartbeat >=
          this.lastPresenceReport + PRESENCE_HEARTBEAT - 5000;
        if (isNewPresenceState || presenceHeartbeatElapsed) {
          this.updatePresenceState({ state: nextState });
          this.setLastPresenceReport(tickData.presenceHeartbeat);
          this.updatePresence(
            nextState,
            this.presenceState.doNotDisturb,
            this.presenceState.info
          );
        }
      },
      {
        name: 'presenceTick',
        fireImmediately: true,
        delay: 1000,
      }
    );

    /*
            This is used to trigger setConversationAndTotalUnreadCount if the currentConversation
            is part of TotalConvosWithUnread when the browser tab is re-focused
        */
    window.addEventListener('focus', () => {
      this.setBrowserTabFocused(true);
    });
    window.addEventListener('blur', () => {
      this.setBrowserTabFocused(false);
    });

    return this.presenceReportTick;
  };

  /**
   * Unread counts by `Conversation` Id
   *
   */
  @observable
  conversationUnreadCounts: ObservableMap<
    string,
    IUnreadMessageAndMentionCounts
  > = observable.map<string, IUnreadMessageAndMentionCounts>();

  /**
   * Select the unread count for a `Conversation`.
   *
   * If the `Conversation` Id key doesn't exist in `conversationUnreadCounts`, a new key will be created with value 0.
   */
  selectConversationUnreadCounts = createTransformer(
    (conversationId: string) => {
      if (!this.conversationUnreadCounts.has(conversationId)) {
        this.conversationUnreadCounts.set(conversationId, {
          unreadMentions: 0,
          unreadMessages: 0,
        });
        return { unreadMentions: 0, unreadMessages: 0 };
      }
      return this.conversationUnreadCounts.get(conversationId);
    }
  );

  /**
   * `Message`s from which a `Conversation` was _Marked as Read_ (via the divider bar), by `Conversation` Id
   */
  @observable
  markedAsReadInfo: ObservableMap<string, IMarkedAsReadInfo> = observable.map<
    string,
    IMarkedAsReadInfo
  >();

  @action
  setMarkedAsReadMessageId = (
    conversationId: string,
    markedAsReadInfo: IMarkedAsReadInfo
  ) => {
    this.markedAsReadInfo.set(conversationId, markedAsReadInfo);
  };

  /**
   * Select the `Message` from which a `Conversation` was _Marked as Read_ (via the divider bar), by `Conversation` Id
   *
   * Returns `null` if the _Mark As Read_ button was not used for the `Conversation`
   */
  selectMarkedAsReadInfo = createTransformer<string, IMarkedAsReadInfo>(
    (conversationId: string) => {
      if (!this.markedAsReadInfo.has(conversationId)) {
        // *Notice that the `moment` value is not observable! Recomputes only when `this.markedAsReadInfo` contains the `conversationId` key. (RP 2019-08-30)
        return {
          markedEpochMs: moment().tz(TIMEZONE_IDENTIFIER).valueOf(),
          messageId: null,
        };
      }
      return this.markedAsReadInfo.get(conversationId);
    }
  );

  /**
   * Select the ms **since** the Unix Epoch that a `Conversation` was _Marked as Read_ (via the divider bar), by `Conversation` Id
   *
   * Returns `0` if the _Mark As Read_ button was not used for the `Conversation`
   */
  selectEpochMsSinceMarkedAsRead = createTransformer(
    (conversationId: string) => {
      if (
        !this.markedAsReadInfo.has(conversationId) ||
        isNullOrUndefined(this.markedAsReadInfo.get(conversationId))
      ) {
        return 5001; // *Will allow ms conditions in ContextContentItemGroup to pass (> 5000 ms) RP 2019-08-30
      }
      // *Notice that the `moment` value is not observable! Recomputes only when `this.markedAsReadInfo` contains the `conversationId` key. (RP 2019-08-30)
      return (
        moment().tz(TIMEZONE_IDENTIFIER).valueOf() -
        this.markedAsReadInfo.get(conversationId).markedEpochMs
      );
    }
  );

  /**
   * Computed sum of all `this.conversationUnreadCounts`
   *
   * @readonly
   */
  @computed
  get TotalUnreadMessageCountsComputed() {
    return Array.from(
      this.conversationUnreadCounts.values()
    ).reduce<IUnreadMessageAndMentionCounts>(
      (prev, next) => {
        prev.unreadMentions += next.unreadMentions;
        prev.unreadMessages += next.unreadMessages;
        return prev;
      },
      { unreadMentions: 0, unreadMessages: 0 }
    );
  }

  /**
   * Set the Unread Count for a `Conversation`, either by incrementing it or setting an explicit number.
   * This will cause the `TotalUnreadMessageCountComputed` to re-compute.
   *
   * Will automatically set `unreadCount`, `showUnread`, and `unreadMentions` (if a number is passed for `methodOrCount`).
   * @param conversationId Conversation Id
   * @param messageMethodOrCount If 'add', increment the Unread Message Count by 1. If a number, set it as the Unread Count. If 0, clear the tab indicator (red dot).
   * @param [convPromise=null] Explicitly provide a Conversation to modify (set unreads, mention flag, etc.),
   * or pass `null` to attempt to load it from the ConversationStore
   * @param mentionMethodOrCount If 'add', increment the Unread Mention Count by 1. If a number, set it as the Mention Count. If 0, clear the mention indicator (at icon).
   */
  @action
  setConversationAndTotalUnreadCount = (
    conversationId: string,
    messageMethodOrCount: 'add' | number,
    convPromise: PromiseLike<AxiosResponseT<ConversationModel>> = null,
    mentionMethodOrCount: 'add' | number = null
  ) => {
    const currentConversationId = getCurrentConversationId();
    const conv =
      convPromise !== null
        ? convPromise
        : (this.rootStore.conversationStore.selectConversationById(
            conversationId
          ) as PromiseLike<AxiosResponseT<ConversationModel>>); // `IPromiseBasedObservable` is fine because it extends `PromiseLike`
    // Eagerly initialize unread count entry
    if (!this.conversationUnreadCounts.has(conversationId)) {
      this.conversationUnreadCounts.set(conversationId, {
        unreadMessages: 0,
        unreadMentions: 0,
      });
    }
    let unreadResult: PromiseLike<IUnreadCountsResult> = null;
    if (messageMethodOrCount === 'add') {
      // Check if this is the currently focused convo
      if (currentConversationId !== conversationId || !this.IsFocused) {
        unreadResult = this.setConversationUnreadCount(
          conversationId,
          messageMethodOrCount,
          conv,
          mentionMethodOrCount
        );
      } else if (currentConversationId === conversationId && this.IsFocused) {
        unreadResult = this.setConversationUnreadCount(
          conversationId,
          0,
          conv,
          mentionMethodOrCount
        );
      }
    } else if (typeof messageMethodOrCount === 'number') {
      unreadResult = this.setConversationUnreadCount(
        conversationId,
        messageMethodOrCount,
        conv,
        mentionMethodOrCount
      );
    } else {
      console.error(
        `Method ${messageMethodOrCount} is unknown for setConversationAndTotalUnreadCount(). Returning 0 counts.`
      );
      return conv.then(() => {
        return {
          conversationUnreadCounts: { unreadMentions: 0, unreadMessages: 0 },
          totalUnreadCounts: this.TotalUnreadMessageCountsComputed,
        } as IUnreadCountsResult;
      });
    }

    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';

    return unreadResult.then((unreadCountsResult) => {
      const totalUnreads = unreadCountsResult.totalUnreadCounts;
      // Update the tab title
      if (
        totalUnreads.unreadMessages !== 0 &&
        !this.rootStore.personStore.isAutoLogOut
      ) {
        link.href = '/favicon-dot.ico';
        this.setTotalUnreadMessagesCount(totalUnreads.unreadMessages);
      } else {
        this.setTotalUnreadMessagesCount(0);
        link.href = '/favicon.ico';
      }
      document.getElementsByTagName('head')[0].appendChild(link);
      sendIpcUnreadCounts(unreadCountsResult);
      return unreadResult;
    });
  };

  @observable
  totalUnreadMessagesCount = 0;

  @action
  setTotalUnreadMessagesCount = (count: number) => {
    this.totalUnreadMessagesCount = count;
  };

  @observable
  showOnlyDirectMentions: boolean;

  @action
  setLocalOnlyDirectMentions = (value: boolean) =>
    (this.showOnlyDirectMentions = value);

  @action
  setShowOnlyDirectMentions = async (showOnlyDirectMentions: boolean) => {
    API.patch(API_ENDPOINTS.Preference, [
      {
        op: 'replace',
        path: '/directMentionsOnly',
        value: showOnlyDirectMentions,
      },
    ]);
    this.setLocalOnlyDirectMentions(showOnlyDirectMentions);
  };

  @action
  getOnlyMentions = async () => {
    const preferences = await fromPromise(API.get(API_ENDPOINTS.Preference));
    const { directMentionsOnly } = preferences?.data;
    if (!isNullOrUndefined(directMentionsOnly)) {
    }
    return directMentionsOnly;
  };

  public listOfMutedConversation = new ObservableMap<string, boolean>();

  // this will return if conversation is muted or not
  selectIfConvMuted = createTransformer((conversationId: string) => {
    if (!this.listOfMutedConversation.has(conversationId)) {
      return false;
    }
    return this.listOfMutedConversation.get(conversationId);
  });

  // sets the conversation is muted
  @action
  setConvMuted = (conversationId: string, muted = false) => {
    const method = muted ? 'add' : 'remove';
    return runInAction(() => {
      this.listOfMutedConversation.set(conversationId, muted);
      void this.rootStore.preferenceStore
        .getExistingPreferenceData()
        .then((resp) => {
          return resp.mutedConversationIds.findIndex(
            (c) => c === conversationId
          );
        })
        .then((conversationIndex) => {
          const path = muted
            ? `/MutedConversationIds/-`
            : `/MutedConversationIds/${conversationIndex}`;
          const request = fromPromise(
            API.patch(API_ENDPOINTS.Preference, [
              {
                op: method,
                path: path,
                value: conversationId,
              },
            ])
          );
          void request.then(() => {
            pushToGTMDataLayer('conversationMutedToggle', {
              muted,
              conversationId,
            });
          });
        });
    });
  };

  @action
  getMutedConv = async () => {
    await this.rootStore.preferenceStore.getExistingPreferenceData();
    const { mutedConversationIds } = this.rootStore.preferenceStore.preferences;
    if (!isNullOrUndefined(mutedConversationIds)) {
      // mapping conversationId to Observable
      return runInAction(() => {
        this.muteConversationLocally(mutedConversationIds);
      });
    }
  };

  @action
  muteConversationLocally = (conversationIds: string[]) => {
    this.listOfMutedConversation.clear();
    conversationIds.forEach((conversationId) => {
      this.listOfMutedConversation.set(conversationId, true);
    });
  };

  @action
  private setConversationUnreadCount(
    conversationId: string,
    messageMethodOrCount: 'add' | number,
    convPromise: PromiseLike<AxiosResponseT<ConversationModel>> = null,
    mentionMethodOrCount: 'add' | number = null
  ) {
    // [BC-1208] Must check for `null` instead of `undefined` (because `null` is explicitly returned if the Conversation is missing)
    if (convPromise === null) {
      convPromise = this.rootStore.conversationStore.loadConversationByIdGet(
        conversationId,
        false // prevent reactivating an archived conversation
      );
    }
    return convPromise.then((c) => {
      return runInAction(() => {
        const newUnreadCounts: IUnreadMessageAndMentionCounts = {
          unreadMentions: 0,
          unreadMessages: 0,
        };
        const urCount: IUnreadMessageAndMentionCounts =
          this.conversationUnreadCounts.has(conversationId)
            ? this.conversationUnreadCounts.get(conversationId)
            : // TODO: Once [BC-2019] is done and unread Mentions is a count (instead of boolean), use that count as the fallback here. (RP 2019-07-30)
              {
                unreadMentions: c.data.unreadMentions
                  ? c.data.unreadMentionsCount
                  : 0,
                unreadMessages: c.data.unreadCount,
              };
        if (messageMethodOrCount === 'add') {
          newUnreadCounts.unreadMessages = urCount.unreadMessages + 1;
        } else if (typeof messageMethodOrCount === 'number') {
          newUnreadCounts.unreadMessages = messageMethodOrCount;
        } else {
          newUnreadCounts.unreadMessages = urCount.unreadMessages;
        }

        if (mentionMethodOrCount === 'add') {
          newUnreadCounts.unreadMentions = urCount.unreadMentions + 1;
        } else if (typeof mentionMethodOrCount === 'number') {
          newUnreadCounts.unreadMentions = mentionMethodOrCount;
        } else {
          newUnreadCounts.unreadMentions = urCount.unreadMentions;
        }

        this.conversationUnreadCounts.set(conversationId, newUnreadCounts);

        // TODO: Remove statements directly modifying `Conversation` fields, instead rely on `conversationUnreadCounts` as the source of truth (RP 2019-07-30)
        c.data.setUnreadCount(newUnreadCounts.unreadMessages);
        // Don't show Unread if the new count is 0
        c.data.setShowUnreadCount(newUnreadCounts.unreadMessages !== 0);
        // Clear mentions flag if count is 0
        c.data.setUnreadMentions(newUnreadCounts.unreadMentions !== 0);
        c.data.setUnreadMentionsCount(newUnreadCounts.unreadMentions);
        return {
          conversationUnreadCounts: newUnreadCounts,
          totalUnreadCounts: this.TotalUnreadMessageCountsComputed,
        } as IUnreadCountsResult;
      });
    });
  }

  @observable
  activeSidebarItem: 'history' | 'recent' = 'recent';

  @action
  setActivityItem = (activeItem: 'history' | 'recent') =>
    (this.activeSidebarItem = activeItem);

  @observable
  emojiPickerState: IEmojiPickerState = {
    open: false,
    editing: false,
  };

  // Declare as Partial to make compatible with other Partial prop requirements
  getEmojiPickerState = () => this.emojiPickerState; // override `ComputedValue` interface assertion

  @action
  setEmojiPickerState = (state: Partial<IEmojiPickerState>) => {
    this.emojiPickerState = {
      ...this.emojiPickerState,
      ...state,
    };
  };

  returnProperText = (index) => {
    switch (index) {
      case 0:
        return 'hours';
      case 1:
        return 'minutes';
      case 2:
        return 'seconds';
    }
  };

  sortUserCriteria = (a, b) => {
    const possibleNumberA = Number(
      this.rootStore.personStore.removeAllSpecialCaracters(a.text)
    );
    const possibleNumberB = Number(
      this.rootStore.personStore.removeAllSpecialCaracters(b.text)
    );

    if (isNaN(possibleNumberA) && isNaN(possibleNumberB)) {
      return a.text.localeCompare(b.text);
    } else if (!isNaN(possibleNumberA) && !isNaN(possibleNumberB)) {
      return possibleNumberA - possibleNumberB;
    } else if (!isNaN(possibleNumberA) && isNaN(possibleNumberB)) {
      return 1;
    } else if (isNaN(possibleNumberA) && !isNaN(possibleNumberB)) {
      return -1;
    }
    return 0;
  };

  transFromStringToHex = (fileName) => {
    const arr1 = [];
    for (let n = 0, l = fileName.length; n < l; n++) {
      const hex = Number(fileName.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return `%${arr1.join('%')}`;
  };

  formatTimeStamp = (time: string) => {
    const hasDays = time.split('.');
    const duration = time.split(':');
    const formated = duration.map((segment, index) => {
      if (segment[0] === '0') {
        segment = segment.substring(1);
      }
      if (parseInt(segment) > 0) {
        const text = this.returnProperText(index);
        return `${segment} ${text}`;
      }
    });

    if (hasDays[0] && hasDays.length > 1) {
      formated?.shift();
      const text = parseInt(hasDays[0]) === 1 ? 'day' : 'days';
      formated?.unshift(`${hasDays[0]} ${text}`);
    }

    return formated?.filter((el) => el)?.join(', ');
  };

  getTimeLeft = (expDate: string) => {
    if (!expDate) {
      return 'granted';
    } else if (moment(expDate).isBefore(moment())) {
      return 'expired';
    } else {
      const difference = moment(expDate).diff(moment());
      const duration = moment.duration(difference);
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes());
      if (days > 0) {
        return `${days} ${days === 1 ? 'day' : 'days'}`;
      } else if (hours > 0) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
      } else if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
      } else {
        return 'expired';
      }
    }
  };

  bytesToSize = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  dateDiff = (start: Moment, end: Moment) => {
    return start.diff(end);
  };
}

export interface IEmojiPickerState {
  open: boolean;
  editing: boolean;
}

/** Reaction data from presenceSyncTick */
export interface IPresenceSyncTickReactionData {
  presenceSyncHeartbeat: number;
}

/**
 * Returned from the `data` function of the `reaction` triggered by `startPresenceTick`.
 * The `reaction` will only react if one of these properties changes.
 * @see https://github.com/mobxjs/mobx/blob/54557dc319b04e92e31cb87427bef194ec1c549c/docs/refguide/reaction.md
 */
export interface IPresenceTickReactionData {
  /** Whether there is an active phone call */
  phoneCallConnected: boolean;
  /** Whether the window/document is not Focused and Active */
  isBlurred: boolean;
  /** Latest value of `now(PRESENCE_HEARTBEAT)` */
  presenceHeartbeat: number;
  isLoggedIn: boolean;
}
/**
 * Returned from the `data` function of the `reaction` triggered by `startWakeUpCheckTick`.
 * The `reaction` will only react if one of these properties changes.
 * @see https://github.com/mobxjs/mobx/blob/54557dc319b04e92e31cb87427bef194ec1c549c/docs/refguide/reaction.md
 */
export interface IWakeUpTickReactionData {
  /** Latest value of `now(WAKEUP_INTERVAL)` */
  wakeUpHeartBeat: number;
  isLoggedIn: boolean;
}

export interface IUiBridgePresenceState {
  state: STATE_PRESENCE;
  doNotDisturb: boolean;
  info?: string;
}

export interface IMarkedAsReadInfo {
  /** Milliseconds from Unix Epoch of the last time a `Message` was marked as read within a `Conversation` */
  markedEpochMs: number;
  /** The `Message.id` that is marked as read */
  messageId: string;
}

export default UiStore;
